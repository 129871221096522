"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const TWO_MINUTES = 1000 * 60 * 2;
const THIRTY_MINUTES = 1000 * 60 * 30;
const ONE_DAY = 1000 * 60 * 60 * 24;
const SIX_MONTHS = ONE_DAY * 30 * 6; // this is an approximation

const times = {
  TWO_MINUTES,
  THIRTY_MINUTES,
  ONE_DAY,
  SIX_MONTHS
};
var _default = times;
exports.default = _default;
module.exports = exports.default;